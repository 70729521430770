// @flow

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { compose } from 'redux'
import TextareaAutosize from 'react-textarea-autosize'
import type { Dispatch } from 'redux'

import type { User } from '../../Auth/Login/Login.reducer'
import * as actions from './ResendInvitePopUp.actionTypes'
import { USER_GROUPS } from '../../../constants'
import { brToNewLine, newLineToBr } from '../../../utils/text'
import ValidationText from '../../ValidationText'
import Button from '../../Button'

import styles from './ResendInvitePopUp.module.scss'

type Props = {
  dispatch: Dispatch,
  error: string,
  getValidationMessages: string => Array<string>,
  group: string,
  maxLength: number,
  onHide: () => void,
  onSave: string => void,
  reinvite: boolean,
  saving: boolean,
  single: boolean,
  t: (string, ?{ [string]: string }) => string,
  ukName: string,
  user: User,
  users: Array<Object>,
  usersResend: Array<Object>,
  working: boolean,
}

type State = { isEmailMessageInvalid: boolean, message: string }

class ResendInvitePopUp extends Component<Props, State> {
  state = {
    message: '',
    isEmailMessageInvalid: false,
  }

  componentDidMount() {
    this.props.dispatch({
      type: actions.RESEND_INVITE_POP_UP_INITIATE,
    })

    this.setState({ message: brToNewLine(this.getDefaultMessage()) })

    document.body.style.overflowY = 'hidden'
  }

  componentDidUpdate(prevProps) {
    const { saving, onHide } = prevProps

    if (!this.props.error && saving && !this.props.saving) {
      onHide()
    }
  }

  componentWillUnmount() {
    this.props.dispatch({ type: actions.RESEND_INVITE_POP_UP_RESET })

    document.body.style.overflowY = 'scroll'
  }

  onSubmit = event => {
    event.preventDefault()

    const { isEmailMessageInvalid, message } = this.state

    if (isEmailMessageInvalid) {
      return null
    }

    const { usersResend, onSave, reinvite } = this.props
    const params = {
      users: usersResend,
      message: newLineToBr(message),
      reinvite,
    }

    this.props.dispatch({
      type: actions.RESEND_INVITE_POP_UP_SAVE,
      params,
      onSave,
    })
  }

  getDefaultMessage() {
    const { user, group, ukName, invitationText } = this.props
    const name = (user && user.fullname) || '%profile_name%'

    const defaultMessage =
      group === USER_GROUPS.dweller
        ? invitationText
        : this.props.t('DefaultManagerMessage', { name, ukName })

    return defaultMessage
  }

  setMessage = e => {
    const {
      target: { value },
    } = e

    const { maxLength } = this.props

    this.setState({
      message: value,
      isEmailMessageInvalid: value.length > maxLength,
    })
  }

  renderError = field => {
    const { getValidationMessages, t } = this.props
    const errors = getValidationMessages(field).map(err => t(err))
    const html = errors[0]

    return errors.length ? (
      <div className='input__msg' dangerouslySetInnerHTML={{ __html: html }} />
    ) : null
  }

  renderCellTitle = (cell, label) => (
    <div className={`m-a06__cell manage_or_admin m-a06__cell--${cell}`}>
      <span className='m-a06__label m-a06__label--01'>{label}</span>
    </div>
  )

  render() {
    const { isEmailMessageInvalid, message } = this.state
    const { reinvite, working, saving, single, ukName, maxLength } = this.props

    const wrapperClass = classnames('modal__content', {
      'working-overlay': working || saving,
    })

    const textAreaClass = classnames(
      'textarea-2__input invite-message',
      styles.textarea
    )

    let title = reinvite
      ? this.props.t('Send')
      : this.props.t('Title', { ukName })
    const okButtonTitle = reinvite
      ? this.props.t('ButtonSend')
      : this.props.t('Send')

    if (single) {
      title = this.props.t('SingleSendTitle')
    }

    return (
      <div className={wrapperClass}>
        <button
          className='modal__close'
          type='button'
          onClick={this.props.onHide}
        />
        <div className='modal__title'>{title}</div>
        <div className='m-a06 '>
          <div className='m-a06__row m-a06__03'>
            <span
              className='m-a06__label m-a06__label--01'
              style={{ marginTop: 10 }}
            >
              {this.props.t('Message')}
            </span>
            <div className='m-a06__02 textarea-2 textarea-2--large textarea-2--block textarea-2--default'>
              <TextareaAutosize
                className={textAreaClass}
                value={message}
                onChange={this.setMessage}
              />
              <div className='textarea-2__placeholder'>
                {this.props.t('MessagePlaceholder')}
                <br />
                {this.props.t('MessageLength')}
              </div>
            </div>
            <ValidationText
              active={isEmailMessageInvalid}
              text={this.props.t('errorLengthEmailMessage', {
                maxLength: maxLength.toString(),
              })}
            />
          </div>
          <div className='m-a06__control modal__control'>
            <Button.Save
              working={saving}
              disabled={isEmailMessageInvalid}
              onClick={this.onSubmit}
            >
              {okButtonTitle}
            </Button.Save>
            <Button.Cancel onClick={this.props.onHide}>
              {this.props.t('Cancel')}
            </Button.Cancel>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  ...state.resendInvitePopUp,
  ukName: state.init.uk.name,
  maxLength: state.init.uk_config.invite_max_length,
  invitationText: state.init.uk_config.invitation_text_for_dwellers,
})

export default compose(
  withTranslation('AddUserPopup'),
  connect(mapStateToProps)
)(ResendInvitePopUp)
