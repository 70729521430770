// @flow

import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { get, isEqual } from 'lodash-es'
import classnames from 'classnames'

import DateTime from '../../DateTime'
import Icon from '../../Icon'
import SelectCustom from '../../Select/SelectCustom'
import MessageAttachments from '../../Chatroom/MessageAttachments'
import { getFullDateFormat } from '../../../utils/commonSelectors'
import { getUserName } from '../../../utils/utils'
import NewMailReplyForm from '../NewMailReplyForm'
import Message from './Message'
import ThreadsListItemErrors from '../../../containers/Mail/ThreadsListItemErrors'
import Loader from '../../Loader'

import styles from './MailMessageFull.module.scss'
import ThreadsListItemRequests from '../../../containers/Mail/ThreadsListItemRequests'

type Props = {
  avatar: string,
  bcc_objs: Array<string>,
  buildingId?: number,
  cc_objs: Array<string>,
  created: string,
  created_requests?: Array<Object>,
  createProps: Object,
  dateFormat: string,
  externalFiles: Array<Object>,
  files: Array<Object>,
  filesHeaderTitle?: string,
  hasSystemEmail: boolean,
  isLastMessage: boolean,
  isMass?: boolean,
  isMenuHidden: boolean,
  isRequestThread?: boolean,
  isSystemIncluding?: boolean,
  isUpdating?: boolean,
  languageCode: string,
  onAttachToRequest: () => void,
  onCollapse: string => void,
  onConverToRequest: () => void,
  onDelete: () => void,
  onRestore: () => void,
  onSave: () => void,
  onToggleMass?: boolean => void,
  outbound?: string,
  owner_obj: Object,
  pending?: boolean,
  removeExternalFile: number => void,
  requestId: number,
  shared: boolean,
  showAddButton: boolean,
  showReplyForm: boolean,
  softArchived: boolean,
  t: string => string,
  text: string,
  thread: string,
  threadTitle: string,
  to_objs: Array<string>,
  uuid: string,
  withProviders: boolean,
  working: boolean,
}

type State = {
  isOpen: boolean,
  replyCallback: Function | null,
  showReplyForm: boolean,
}

class MailMessageFull extends Component<Props, State> {
  state = {
    showReplyForm: this.props.showReplyForm,
    replyCallback: null,
    isOpen: false,
    links: [...this.props.linked_requests],
  }

  componentDidUpdate(prevProps) {
    const { attached_request, linked_requests } = this.props

    if (
      prevProps.attached_request?.message_id !== attached_request?.message_id &&
      attached_request &&
      !this.state.links?.find(req => req.id === attached_request.id)
    ) {
      this.setState(state => {
        return { links: [attached_request, ...state.links] }
      })
    }

    if (!isEqual(prevProps.linked_requests, linked_requests)) {
      this.setState({ links: [...linked_requests] })
    }
  }

  getUserInfo = user => {
    const userName = getUserName(user)
    const email = get(user, 'email')
    const emailString = email && email !== userName ? ` [${email}]` : ''

    return `${userName}${emailString}`
  }

  getUsers = () => {
    const {
      participants: { to, cc, bcc },
    } = this.props

    let fieldValue = []

    const toFieldValue = to.map(this.getUserInfo)
    const ccFieldValue = cc.map(this.getUserInfo)
    const bccFieldValue = bcc.map(this.getUserInfo)

    if (toFieldValue.length) {
      fieldValue = fieldValue.concat(toFieldValue).join(', ')
      fieldValue = `${this.props.t('MessageTo')} ${fieldValue}`
    } else {
      fieldValue = ''
    }

    if (ccFieldValue.length) {
      const ccFieldString = ccFieldValue.join(', ')

      fieldValue = `${fieldValue}${fieldValue ? ', ' : ''}${this.props.t(
        'MessageCC'
      )} ${ccFieldString}`
    }

    if (bccFieldValue.length) {
      const bccFieldString = bccFieldValue.join(', ')

      fieldValue = `${fieldValue}${fieldValue ? ', ' : ''}${this.props.t(
        'MessageBCC'
      )} ${bccFieldString}`
    }

    return fieldValue
  }

  onClickReplyButton = e => {
    e.stopPropagation()

    const { onGetReplyValues } = this.props.createProps

    this.onChangeMoreButton({
      value: 'onReply',
      handler: this.setReplyCallback(onGetReplyValues),
    })
  }

  onClickCloseArea = () => {
    const { uuid, onCollapse } = this.props

    onCollapse(uuid)
  }

  onChangeMoreButton = (option: Object) => {
    const { uuid, owner_obj: owner } = this.props
    const openForm = ['onReply', 'onReplyAll', 'onForward'].includes(
      option.value
    )

    if (openForm) {
      this.setState({ showReplyForm: true })
    }

    if (option.handler) {
      option.handler(uuid, owner)
    }
  }

  resetForm = () => {
    const { isLastMessage } = this.props

    if (!isLastMessage) {
      this.setState({ showReplyForm: false })
    }
  }

  setReplyCallback = (callback: Function) => () => {
    this.setState({ replyCallback: callback })
  }

  getMoreButtonOptions() {
    const {
      uuid,
      createProps,
      softArchived,
      shared,
      isMenuHidden,
      permissions,
      isRequestThread,
    } = this.props

    const onReply = this.setReplyCallback(createProps.onGetReplyValues)
    const onReplyAll = this.setReplyCallback(createProps.onGetReplyAllValues)
    const onForward = this.setReplyCallback(createProps.onGetForwardValues)

    const onPrint = () => {
      const { thread, request_thread, requestId } = this.props
      window.open(
        isRequestThread
          ? `/request/${requestId}/emails/${request_thread}/print/${uuid}`
          : `/mail/${thread}/print/${uuid}`
      )
    }

    let options = []

    if (!softArchived) {
      if (!shared) {
        if (permissions.can_be_resent) {
          options.push({
            value: 'onResend',
            label: this.props.t('onResend'),
            handler: this.props.onResend,
            icon: 'restore',
          })
        }

        if (!isMenuHidden) {
          options.push({
            value: 'onReply',
            label: this.props.t('ReplyButton'),
            handler: onReply,
            icon: 'reply',
          })
        }

        options.push({
          value: 'onReplyAll',
          label: this.props.t('ReplyAllButton'),
          handler: onReplyAll,
          icon: 'replyAll',
        })

        options.push({
          value: 'onForward',
          label: this.props.t('ForwardButton'),
          handler: onForward,
          icon: 'forward',
        })
      }

      options.push({
        value: 'onPrint',
        label: this.props.t('PrintButton'),
        handler: onPrint,
        icon: 'printer',
      })

      options.push({
        value: 'onConverToRequest',
        label: this.props.t('ConvertToRequestButton'),
        handler: this.props.onConverToRequest,
        icon: 'request',
      })

      options.push({
        value: 'onAttachToRequest',
        label: this.props.t('AttachToRequestButton'),
        handler: () => this.props.onAttachToRequest(uuid, this.state.links),
        icon: 'pin',
      })
    }

    if (!shared) {
      if (softArchived && !isMenuHidden) {
        options.push({
          value: 'onRestore',
          label: this.props.t('RestoreButton'),
          handler: this.props.onRestore,
          icon: 'bin',
        })
      } else {
        options.push({
          value: 'onDelete',
          label: this.props.t(
            isRequestThread ? 'DeleteButtonRequest' : 'DeleteButton'
          ),
          handler: this.props.onDelete,
          icon: isMenuHidden ? 'remove' : 'bin',
        })
      }
    }

    return options
  }

  setOpen = isOpen => this.setState({ isOpen })

  render() {
    const {
      files,
      text,
      created,
      avatar,
      pending,
      createProps,
      sender_display_name,
      sender_email,
      working,
      softArchived,
      dateFormat,
      filesHeaderTitle,
      showAddButton,
      externalFiles,
      shared,
      requestId,
      threadTitle,
      isSystemIncluding,
      isRequestThread,
      errors,
      isMass,
      isUpdating,
      onToggleMass,
      isDeny,
      isDweller,
      directoryId,
      scope,
      buildingId,
      object,
      flatId,
      outbound,
    } = this.props

    const { showReplyForm, replyCallback, links } = this.state

    const name = sender_display_name || sender_email
    const options = this.getMoreButtonOptions()
    const isMenuShown = options && !!options.length
    const isReplyShown = !softArchived && !shared

    const messageClass = classnames('ql-editor', styles.content)

    return (
      <div className={styles.message}>
        <div className={styles.info} onClick={this.onClickCloseArea}>
          <div className={styles.avatar}>
            <img src={avatar} />
          </div>
          <div className={styles.user}>
            <div className={styles.cell}>
              <span className={styles.name}>{name}</span>
              <span className={styles.email}>{` [${sender_email}]`}</span>
              <ThreadsListItemErrors
                hasError={!!(errors && errors.length)}
                errors={errors}
                style={{ flexShrink: 0 }}
                className={styles.error}
              />
            </div>
            {pending ? (
              <>
                <Loader type='small' style={{ width: '16px' }} text={false} />
                <span className={styles.date} style={{ color: '#888' }}>
                  {this.props.t('Sending')}
                </span>
              </>
            ) : (
              <DateTime
                dateTime={created}
                dateFormat={dateFormat}
                className={styles.date}
              />
            )}
            <ThreadsListItemRequests
              requests={links}
              viewedCount={isRequestThread ? 2 : 3}
            />
            <div className={styles.to}>{this.getUsers()}</div>
          </div>
          {!isDweller && !outbound && (
            <div className={styles.actions}>
              {isReplyShown && (
                <Icon
                  colorOnHover
                  className='button--icon'
                  id='reply'
                  onClick={this.onClickReplyButton}
                />
              )}
              {isMenuShown && (
                <SelectCustom
                  setOpen={this.setOpen}
                  options={options}
                  onChange={this.onChangeMoreButton}
                />
              )}
            </div>
          )}
        </div>
        <div className={messageClass}>
          <Message text={text} />
        </div>
        <MessageAttachments
          files={files}
          scope={scope}
          object={object}
          outbound={outbound}
        />
        {!softArchived &&
          !shared &&
          showReplyForm &&
          !isDweller &&
          !outbound && (
            <NewMailReplyForm
              {...createProps}
              withProviders
              directoryId={directoryId}
              isSystemIncluding={isSystemIncluding}
              isRequestThread={isRequestThread}
              removeExternalFile={this.props.removeExternalFile}
              showAddButton={showAddButton}
              filesHeaderTitle={filesHeaderTitle}
              replyCallback={replyCallback}
              working={working}
              threadTitle={threadTitle}
              externalFiles={externalFiles}
              resetForm={this.resetForm}
              requestId={requestId}
              buildingId={buildingId}
              isMass={isMass}
              isSending={isUpdating}
              isDeny={isDeny}
              setSelectedFiles={this.props.setSelectedFiles}
              changedValues={this.props.changedValues}
              setChangedValues={this.props.setChangedValues}
              requestFlatId={flatId}
              onSave={this.props.onSave}
              onToggleMass={onToggleMass}
            />
          )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  dateFormat: getFullDateFormat(state),
})

export default compose(
  withTranslation('Mail'),
  connect(mapStateToProps)
)(MailMessageFull)
